import React, { useRef, useState } from "react";

import styles from "./Registration.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
  showWarnToastNotification,
} from "../../components/ToastNotification/ToastNotification";
import { validateRegForm } from "../../validators/regForm";
import FormField from "../../components/FormField/FormField";

import nameIcon from "../../assets/icons/name.png";
import deptIcon from "../../assets/icons/department.png";
import yearIcon from "../../assets/icons/year.png";
import collegeIcon from "../../assets/icons/college.png";
import emailIcon from "../../assets/icons/email.png";

import phoneIcon from "../../assets/icons/phone.png";
import { apiRegisterUser } from "../../api/registerUser";
import RegistrationInfoPane from "./RegistrationInfoPane";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const formStateFormat = {
  name: "",
  year: 1,
  dept: "",
  college: "",
  email: "",
  phone: "",
  workshopsandevents: ""
};

const Registration = () => {
  ScrollToTop();

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile);
    showSuccessToastNotification(<p>Selected File: {file.name}</p>);

  };


  const [loading, setLoading] = useState();
  const [paymentReq, setPaymentReq] = useState(false);
  const [formState, setFormState] = useState(formStateFormat);

  const changeFormState = (args) => {
    let prevState = formState;

    prevState[args.key] = args.value;

    // if (prevState.workshopsandevents === "ONLYEVENTS") {
    //   setPaymentReq(false);
    // } else {
    //   setPaymentReq(true)
    // };

    setFormState({ ...prevState });
  }

  let reCaptchaRef = useRef(null);

  const handleSubmit = async (e) => {

    e.preventDefault();
    const validation = validateRegForm(formState);
    if (validation.status === false) {
      showWarnToastNotification(validation.message);
      return;
    }

    if ((!file || !file.name.match(/\.(jpg|jpeg|png|gif)$/) ) && paymentReq == true) {
      showWarnToastNotification(<p>Please upload Screenshot of Payment</p>);
      return;
    } else {
      formState.file = file;
    }

    if (reCaptchaRef.current.getValue() === "") {
      showWarnToastNotification(<p>Please select reCaptcha</p>);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    // formData.append('file', file);
    Object.entries(formState).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('captcha', reCaptchaRef.current.getValue());
    let resp;
    try {
      resp = await apiRegisterUser(formData);
      if (resp.status === 201) {
        showSuccessToastNotification(resp.data.message);
        setFormState({
          name: "",
          year: 1,
          dept: "",
          college: "",
          email: "",
          phone: "",
          workshopsandevents: "",
        });
      } else {
        showErrorToastNotification(resp.data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
      showErrorToastNotification('Registration failed. Please try again later.');
    }


    // const resp = await apiRegisterUser({
    //   ...formState,
    //   captcha: reCaptchaRef.current.getValue(),
    // });

    reCaptchaRef.current.reset();

    setLoading(false);

  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.title}`}>
        <h2>Register for Xceed'25</h2>
      </div>
      <div className={styles.contentContainer}>
        {/* Left pane - form */}
        {loading && (
          <div className={styles.loader_container}>
            <SimpleLoader text="Registering..." />
          </div>
        )}
        <form
          className={`${styles.formContent}`}
          style={{ display: loading ? "none" : "flex" }}
        >
          <div className={styles.formHeader}>Get your XCEED ID here!</div>
          <a className={styles.infoLink} href="#regInfo">
            <div className={`${styles.button}`}>Click here for more info</div>
          </a>
          <FormField
            type="text"
            fieldIcon={nameIcon}
            placeholder="Full name"
            name="name"
            value={formState}
            setter={changeFormState}
          />
          <FormField
            type="text"
            fieldIcon={deptIcon}
            placeholder="Department"
            name="dept"
            value={formState}
            setter={changeFormState}
          />
          <FormField
            type="dropdown"
            dropdownValues={[
              { name: "1st year", value: 1 },
              { name: "2nd year", value: 2 },
              { name: "3rd year", value: 3 },
              { name: "4th year", value: 4 },
              { name: "5th year", value: 5 },
            ]}
            fieldIcon={yearIcon}
            placeholder="Year"
            name="year"
            value={formState}
            setter={changeFormState}
          />
          <FormField
            type="text"
            fieldIcon={collegeIcon}
            placeholder="College"
            name="college"
            value={formState}
            setter={changeFormState}
          />
          <FormField
            type="text"
            fieldIcon={emailIcon}
            placeholder="E-mail ID"
            name="email"
            value={formState}
            setter={changeFormState}
          />
          <FormField
            type="phone"
            fieldIcon={phoneIcon}
            placeholder="Phone number"
            name="phone"
            value={formState}
            setter={changeFormState}
          />
          <FormField
            type="dropdown"
            dropdownValues={[
              {
                name: "IoT Unleashed: Bringing Intelligence to Your Fingertips - Free",
                value: "IOT",
              },
              {
                name: "Automotive powertrain: Automobile transmission systems and sensors - Free",
                value: "AUTOMOTIVE",
              },
              { name: "Only Events - Free", value: "ONLYEVENTS" },
            ]}
            fieldIcon={yearIcon}
            placeholder="Workshops and Events"
            name="workshopsandevents"
            value={formState}
            setter={changeFormState}
          />
          {paymentReq &&
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload Screenshot of Payment
              <VisuallyHiddenInput type="file" onChange={handleFileChange} accept="image/*" />
            </Button>
          }
          {file && <p>Selected File: {file.name}</p>}

          <br></br>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            theme="dark"
            size="normal"
            className={`${styles.recaptcha}`}
            ref={reCaptchaRef}
          />
          <div id="regInfo">
            <div className={`${styles.button}`} onClick={handleSubmit}>
              Register
            </div>
          </div>
        </form>

        {/* Right pane - info */}
        <RegistrationInfoPane />
      </div>
    </div>
  );
};

export default Registration;
