import React, { useState, createContext, useEffect } from "react";

import "./App.css";
import "./fonts.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { ThemeProvider } from "styled-components";

import { darkTheme, lightTheme, GlobalStyles } from "./themes";

import Landing from "./pages/Landing/Landing";
import KAwards from "./pages/KAwards/KAwards";
import KAwardsnominee from "./pages/KAwards/KAwardsnominee";
import Xceed from "./pages/Xceed/Xceed";
import XceedType from "./components/XceedTypes/XceedType";
import Karnival from "./pages/Karnival/Karnival";
import Contact from "./pages/Contact/Contact";
import Registration from "./pages/Registration/Registration";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Bg from "./components/Bg/Bg";

import { showSuccessToastNotification } from "./components/ToastNotification/ToastNotification";

const StyledApp = styled.div``;

export const Width = createContext();

toast.configure();

function App() {
  const [width, setwidth] = useState(0);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setwidth(window.innerWidth);
  };

  useEffect(() => {
    // if (window.location.pathname !== "/registration") {
    //   showSuccessToastNotification(<a href="/registration">Click here to register for Xceed - KVCET!</a>)
    // }
    return () => { };
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <StyledApp>
        <Width.Provider value={width}>
          <div className="App">
            <Router>
              <div className="bg">
                <Bg />
                <img src='/bed.png' alt='seabed' className='seabed' />
              </div>
              <Navbar width={width} />
              <AllRoutes />
              <Footer />
            </Router>
          </div>
        </Width.Provider>
      </StyledApp>
    </ThemeProvider>
  );
}

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/Karnival" element={<Karnival />} />
      <Route path="/KAwards" element={<KAwards />} />
      <Route path="/KAwardsnominee" element={<KAwardsnominee />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/PageNotFound" element={<PageNotFound />} />
      <Route path="/Registration" element={<Registration />} />
      <Route path="/Xceed" element={<Xceed />} />
      <Route path="/xceed/national" element={<XceedType type="National Xceed" />} />
      <Route path="/xceed/managerial" element={<XceedType type="Managerial Xceed" />} />
      <Route path="/xceed/corporate" element={<XceedType type="Corporate Xceed" />} />
      <Route path="/xceed/school" element={<XceedType type="School Xceed" />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
