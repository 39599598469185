import React from 'react';
import CardComp from '../../components/CardComp/CardComp';
import { KAWARDS } from '../../data/KawardsData';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import UnderConstruction from '../../components/UnderConstruction/UnderConstruction';
import Box from "@mui/material/Box";
import styles from './KAwards.module.css';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CardCompAccordian from '../../components/CardComp/CardCompAccordian';

function KAwards() {
  ScrollToTop();
  const navigate = useNavigate();
  return (
    <div className={`${styles.content}`}>
      <h1 className={`${styles.title}`}>K! Awards</h1>
      {/* <Box sx={{ "& button": { m: 1 } }}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => navigate("/registration")}
          >
            Apply for XCEED'24!
          </Button>
        </div>
      </Box> */}

      <h4 className={`${styles.desc}`}>
        K! Awards aims to encourage and recognize people who overcame hardships to do
        justice to their potential. The recipients of these awards include budding socially responsible
        innovators, educationalists, entrepreneurs, and social activists. This celebration is
        also embedded with mesmerizing performances of its kind putting the K! Awards
        in a league of its own, unlike other award ceremonies.
      </h4>
      <br />
      <br />
      <ul >
      {KAWARDS.map((item, index) => {
        return <CardCompAccordian
          title={"K! Awardees of previous years"}
          images={item.images}
          list={item.list}
          year={item.yearlist}
        />
      })}
      </ul>
      {/* <UnderConstruction /> */}
      
      <br />
      <br />
    </div>
  )
}

export default KAwards;
