import { createGlobalStyle } from "styled-components";

// Light Theme Config
export const lightTheme = {
   bg: '#ffffff',
   text: '#fff',
   backgroundText: '#DADCE0',
};

// Dark Theme Config
export const darkTheme = {
  bg: "#262626",
  text: "#e1e2e2",
  backgroundText: "#474b4f",
};

// Replace primary and secondary colors

export const GlobalStyles = createGlobalStyle`

    :root {
      --primary:rgba(21, 10, 18, 0.88);
      --secondary:#d7d7aa;
      --tertiary:#58aaec;
      --accent: #bcb1ff;
      --text : ${(props) => props.theme.text};
      --backgroundText : ${(props) => props.theme.backgroundText};
      --bg : ${(props) => props.theme.bg};
    }
    
    body {
        background-color : ${(props) => props.theme.bg};
        transition: all 0.25s linear;
    }

`;
