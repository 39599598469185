import React from 'react';
import styles from './Footer.module.css';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import MediaLink from '../MediaLink/MediaLink';

function Footer() {
   return (
      <div className={styles.footer}>
         <div className={styles.footerLeft}>
            <img src="xceed_logo.png" alt="Xceed Logo" className={styles.logo} />
            <div className={styles.about}>
               <h2>What is  K! ?</h2>
               <p>
                  ‘Kurukshetra’ is the battlefield where the war will happen not with swords, but brains! It is the first fest among its sort in the nation to get a
                  UNESCO patronage. It is an open-stage for all the young minds that are thirsty to prove their innovative skills across the globe. Stay tuned for more
                  updates on the 19th edition of Kurukshetra - The Battle of Brains!
               </p>
            </div>
         </div>
         <div className={styles.footerRight}>
            <h2>Contact Us</h2>
            <div className={styles.iconContainer}>
               <div className={styles.icon}>
                  <CallIcon />
               </div>
               <div>
                  <MediaLink content='Vikashini T' link='+91 90804 69446' type='phone' />
                  <MediaLink content='Vikram D' link='+91 88257 86603' type='phone' />
               </div>
            </div>
            <div className={styles.iconContainer}>
               <div className={styles.icon}>
                  <EmailIcon />
               </div>
               <div>
                  <MediaLink link='xceed@cegtechforum.in' type='mail' />
               </div>
            </div>
         </div>
      </div >
   );
}

export default Footer;
